import { useRouter } from "next/router";

const PathName = () => {
  const { asPath } = useRouter();

  let path = ''
  if (asPath == "/") {
    path = "home";
  } else {
    let pathName = asPath.split("/");
    //removing empty array using filter and joining the path if have children with spaces
    path = pathName.filter((p) => p != "").join(" ");
  }

  return [path];
};

export default PathName;
