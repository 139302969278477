import { createContext, useEffect, useState } from "react";


interface DarkModeContextData {
  darkMode: boolean,
  setDarkMode: (_: boolean) => void
}

export const DarkModeContext = createContext<DarkModeContextData | null>(null);

export const DarkModeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) document.querySelector('html').classList.add('darkMode');
    else document.querySelector('html').classList.remove('darkMode');
  }, [darkMode])

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
};
